import api from '../../api/api';
import {
  LOGIN_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from './types';
import { asyncStart, asyncFinished } from './async.actions';

export const login = (email, password) => async dispatch => {
  try {
    dispatch(asyncStart());
    const response = await api.post('/auth/login', {
      email,
      password,
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });
    dispatch(getCurrentUser(localStorage.getItem('token')));
    dispatch(asyncFinished());
  } catch (e) {
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch(asyncFinished());
  }
};

export const getCurrentUser = token => async dispatch => {
  try {
    dispatch(asyncStart());
    const { data: user } = await api.get('/auth/get_current', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: USER_LOADED,
      payload: user,
    });
    dispatch(asyncFinished());
  } catch (e) {
    dispatch({
      type: AUTH_ERROR,
    });
    dispatch(asyncFinished());
  }
};

export const logout = () => dispatch => {
  dispatch({
    type: LOGOUT,
  });
};

export const signup = ({
  name,
  email,
  password,
  address,
  phone,
}) => async dispatch => {
  try {
    dispatch(asyncStart());
    const response = await api.post('/users', {
      name,
      email,
      password,
      address,
      phone,
    });
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
    });
    dispatch(getCurrentUser(response.data.token));
    dispatch(asyncFinished());
  } catch (e) {
    dispatch({
      type: REGISTER_FAIL,
    });
    dispatch(asyncFinished());
  }
};

export const updateUser = values => async (dispatch, getState) => {
  const { name, address, phone } = values;
  try {
    dispatch(asyncStart());
    await api.put('/users', {
      name,
      address,
      phone,
    });
    dispatch(getCurrentUser(getState().auth.token));
    dispatch(asyncFinished());
  } catch (e) {
    dispatch(asyncFinished());
  }
};
