import axios from 'axios';
// import store from '../store/store'

// const token = store.getState().auth.token || localStorage.getItem('token')
// const token = localStorage.getItem('token')

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000' + process.env.REACT_APP_API_URL
      : process.env.REACT_APP_API_URL,
  // headers: {
  // Authorization: `Bearer ${token}`,
  // },
});

instance.interceptors.request.use(
  config => {
    if (!!localStorage.getItem('token')) {
      config.headers['Authorization'] =
        'Bearer ' + localStorage.getItem('token');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
