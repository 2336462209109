// auth actions
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// async actions
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_FINISHED = 'ASYNC_FINISHED';

// cart actions
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const INCRE_ITEM = 'INCRE_ITEM';
export const DECRE_ITEM = 'DECRE_ITEM';
export const SET_SHIPPING = 'SET_SHIPPING';
export const CLEAR_CART = 'CLEAR_CART';
