import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const CustomBtn = styled(Button)`
  display: block;
  position: fixed;
  right: 10px;
  bottom: 6%;
  border-radius: 50% !important;

  z-index: 200;

  @media (min-width: 768px) {
    right: 20px;
  }

  &:hover,
  &:focus {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const BackToTop = () => {
  const handleClick = () => {
    if (typeof window !== 'undefined') {
      let i = window.scrollY;
      let int = setInterval(() => {
        window.scrollTo(0, i);
        i -= 50;
        if (i <= 50) {
          clearInterval(int);
          window.scrollTo(0, 0);
        }
      }, 5);
    }
  };

  return <CustomBtn color="black" onClick={handleClick} icon="chevron up" />;
};

export default BackToTop;
