import { ASYNC_START, ASYNC_FINISHED } from '../actions/types';

const initialState = {
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ASYNC_START:
      return {
        ...state,
        loading: true,
      };
    case ASYNC_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
