import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PublicOnlyRoute = props => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return !isAuthenticated ? <Route {...props} /> : <Redirect to="/" />;
};

export default PublicOnlyRoute;
