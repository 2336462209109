import { combineReducers } from 'redux';
import auth from './auth.reducer';
import cart from './cart.reducer';
import async from './async.reducer';

export default combineReducers({
  cart,
  auth,
  async,
});
