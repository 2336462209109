import React from 'react';
import { Container, Breadcrumb } from 'semantic-ui-react';
import SignUpTerms from '../components/SignUpTerms';
import { Link } from 'react-router-dom';
import ScrollToTopOnMount from '../utils/ScrollToTopOnMount';

const Terms = () => {
  return (
    <Container>
      <ScrollToTopOnMount />
      <Breadcrumb>
        <Breadcrumb.Section as={Link} to="/">
          首頁
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>會員服務與條款</Breadcrumb.Section>
      </Breadcrumb>
      <hr style={{ margin: '20px 0' }} />

      <SignUpTerms />
    </Container>
  );
};

export default Terms;
