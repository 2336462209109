import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADED,
  REGISTER_SUCCESS,
  AUTH_ERROR,
  REGISTER_FAIL,
  CLEAR_ERROR,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
  error: '',
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
      };
    case REGISTER_FAIL:
      localStorage.removeItem('token');
      return {
        ...initialState,
        token: null,
        loading: false,
        error: '註冊失敗，可能此email已有人使用',
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };
    case LOGIN_FAIL:
      localStorage.removeItem('token');
      return {
        ...initialState,
        token: null,
        loading: false,
        error: '登入失敗，請確定輸入的資料正確',
      };
    case AUTH_ERROR:
    case LOGOUT:
      // case ACCOUNT_DELETED:
      localStorage.removeItem('token');
      return {
        ...initialState,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
