import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import LoadingComponent from '../layouts/common/LoadingComponent';

const PrivateRoute = props => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const loading = useSelector(state => state.async.loading);
  const user = useSelector(state => state.auth.user);
  const isAdmin = user && user.role === 'admin';

  return !loading ? (
    isAuthenticated && isAdmin ? (
      <Route {...props} />
    ) : (
      <>
        <Redirect to="/" />
        {console.log(user, isAuthenticated, isAdmin)}
      </>
    )
  ) : (
    <LoadingComponent />
  );
};

export default PrivateRoute;
