import React from 'react';

const SignUpTerms = () => {
  return (
    <div>
      <p>
        歡迎使用本站服務，本站屬於『薩摩亞商順譽世界企業股份有限公司』（下稱
        薩摩亞），薩摩亞關係企業包含(但不限於) Bonny Live 運動商品服務網，Bonny
        羽球媒合網，Bonny Go
        賽事網，潛立方潛水旅館等；本站的賽事報名者及會員同享薩摩亞關係企業內行銷優惠。
      </p>
      <p>
        加入
        <a
          href="https://www.bonny-live.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Bonny Live網站
        </a>
        會員即可享有薩摩亞關係企業、各合作特約商(特約商名單將會不定期於Bonny
        Live網站更新並公告，詳見
        <a
          href="https://www.bonny-live.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Bonny Live網站
        </a>
        __________________，以下同)贈送申請人之達幣，以累積點數折抵或兌換薩摩亞或各合作特約商提供之商品，亦可享有薩摩亞暨各合作特約商提供薩摩亞、各合作特約商、各合作夥伴之行銷優惠等服務。
      </p>
      <p>
        為了保護會員以及所有使用者的利益，並為服務提供依據，請詳細閱讀各項服務辦法及條款，當您完成會員資料的申請與提供，開始使用各種服務時，即表示您已同意接受各項說明辦法之所有內容。
        約定之服務辦法與條款如有任何修改，都會公佈在
        <a
          href="https://www.bonny-live.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Bonny Live網站
        </a>
        ，建議您隨時注意相關調整。如您於任何調整修改後繼續使用任一服務，表示您已同意接受修改後的約定事項。
      </p>
      <p>
        關於您的會員註冊之個人資料，薩摩亞將依中華民國個人資料保護法與相關法令之規範，以及本網站「隱私權聲明」保護之。
      </p>
      <h5>網站會員資料</h5>
      <ol style={{ listStyleType: 'upper-alpha' }}>
        <li>
          依法告知事項
          <small>
            達賚為提供電子商務交易平台並保護消費者權益，乃建立會員機制，以提供完整之會員服務，謹依個人資料保護法第8條規定告知下列事項：
          </small>
          <ol>
            <li>
              蒐集、處理、利用之目的
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>商業與技術資訊</li>
                <li>客戶管理</li>
                <li>會員管理</li>
                <li>行銷</li>
                <li>資訊與資料庫管理</li>
                <li>統計調查與分析等目的</li>
                <li>個人資料交易</li>
                <li>其他合於營業登記項目或章程所定業務之需要</li>
                <li>其他財政服務</li>
                <li>公益捐贈</li>
              </ol>
            </li>
            <li>
              個人資料之類別：
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>
                  辨識個人者。如姓名、地址、電話、電子郵件、寄送地址及收件人資料等。
                </li>
                <li>
                  辨識財務者。如信用卡或簽帳卡之號碼、個人之其他號碼或帳戶等。
                </li>
                <li>政府資料中之辨識者。如身分證字號或護照號碼。</li>
                <li>個人描述。如性別、年齡、出生年月日、國籍。</li>
                <li>家庭情形。如結婚有無等。</li>
                <li>休閒活動及興趣。如嗜好、運動及其他興趣等。</li>
                <li>
                  生活格調。使用消費品之種類及服務細節、個人或家庭知消費模式等。
                </li>
                <li>財務交易。如支付方式、往來紀錄。</li>
                <li>約定或契約。如關於交易、商業、法律或其他契約等</li>
              </ol>
            </li>
            <li>利用期間</li>
            <li>
              利用地區、對象及方式
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>
                  台灣地區及產品出貨地，且基於履行契約義務，將提供相關資料予會員購買產品之供應商及物流商，或達賚於前揭特定目的範圍內之委外廠商。
                </li>
                <li>達賚將於蒐集之特定目的範圍內處理並利用個人資料。</li>
              </ol>
            </li>
            <li>
              會員就其個人資料依法得向達賚以書面請求行使下列權利:
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>查詢或請求閱覽。</li>
                <li>製給複製本。</li>
                <li>補充或更正。</li>
                <li>請求停止蒐集、處理或利用</li>
                <li>請求刪除。</li>
              </ol>
            </li>
            <li>
              會員行使前條任一權利，請檢附相關證明文件向達賚提出書面申請，達賚就該書面請求之決定，將於收到會員書面請求之次日起15日內以書面通知申請人，前述15日期間於必要時，得予再延長15日，達賚並將於書面通知申請人。如會員就其個人資料向達賚請求查詢、提供閱覽或製給複製本時，達賚將酌收必要成本費用。前述之申請，應填具申請文件，並由本人親自申請，達賚得向您請求提出可資確認身分之證明文件。若委託他人代為申請者，應出具委任書，並提供本人及代理人之之身分證明文件。
            </li>
            <li>
              會員得自由選擇提供個人資料，惟若其提供之資料不足或有誤時，達賚除將無法提供完整之購物服務外，亦將可能無法使其行使Bonny
              GO之會員權利。
            </li>
          </ol>
        </li>
        <li>
          關於網站會員註冊
          <ol>
            <li>
              您同意提供包括但不限於姓名、身分證字號、電話、地址、生日及電子郵件等基本資料進行註冊，以在本網站進行消費及接受本網站會員服務。
            </li>
            <li>
              您確認於註冊時提供個人資料均屬真實，如您個人資料誤填或有變更時，您將隨時更正或更新，達賚將依您所提供之最新資料予以更新或補充，並維護您個人資料之正確性；如您未及時更新，表示您同意達賚繼續依您原登錄資料提供各項服務，若因此所受之不利益，均應由您自行承擔。
            </li>
            <li>
              若您登錄不實資料，達賚將於發現後暫停或終止您的會員資格，並拒絕您使用會員服務。
            </li>
            <li>
              達賚若發現或合理懷疑您個人資料不正確、不慎遺失或遭冒用時，將及時通知您，並停止處理或利用您的個人資料，且暫停該帳號所生交易之處理及後續利用；若您發現或合理懷疑您個人資料不正確、不慎遺失或遭冒用時，您應該立即通知達賚，達賚將採取必要的防範措施並視情況進行調查，但上述通知不代表達賚對會員負有任何形式之賠償或補償的責任。
            </li>
          </ol>
        </li>
        <li>
          網站會員帳號、密碼及安全
          <ol>
            <li>
              達賚將建置符合網路交易需求之安全機制，並告知您該機制之安全程度，以提供您安全的交易平台。
            </li>
            <li>
              註冊網站會員資料時，您必須提供一組個人帳號與辨識密碼，且該帳號不可重複登錄。
            </li>
            <li>
              您應妥善保管帳號及密碼，並於每次使用後確實登出，以防他人盜用，同時，請勿將您的帳號與密碼洩露或提供予第三人知悉，以免因此遭人非法使用，若您未能保管好帳號及密碼，因此所受之損害，將由您自行承擔。
            </li>
          </ol>
        </li>
        <li>
          關於會員提供資訊
          <br />
          請您確認上載、傳送、輸入或提供至本網站的資料，都已合法取得授權使用（下稱授權使用，包含但不限修改、重製、公開播送、改作、散布、發行、公開發表等方式），並可將前述權利轉授權第三人。任何資料一經您上載、傳送、輸入或提供至本網站時，視為您已同意達賚在符合本會員服務及網站目的使用及利用已取得您的授權使用，您對此絕無異議。您並應保證達賚取得授權使用的內容，均無侵害任何第三人智慧財產權的情形，如果因此造成對達賚的損失或損害，您承諾將對達賚負賠償或補償責任。
        </li>
        <li>
          家長或監護人義務
          <br />
          如您是未滿十二歲的兒童，請確認您的家長或監護人已同意您上網使用本網站並且全程在旁陪伴；如您是十二歲以上未滿十八歲的青少年，當您上網前，請您確認您的家長或監護人已經同意您上網使用本網站。
        </li>
        <li>
          資料記錄
          <br />
          當您使用本網站時，在使用過程中所有的資料紀錄，包括交易資料、付款方式及資訊、寄送地址及收件人資料，均將保存於會員服務資料庫記錄，您同意達賚基於履行上述A.1之特定目的範圍內予以蒐集、利用或處理。
        </li>
        <li>
          服務內容之變更與電子報及EDM發送
          <ol>
            <li>
              達賚將得視業務需要及實際情形，增減、變更或終止相關服務的項目或內容，前述內容將公告於Bonny
              Go, 且不再個別通知會員。
            </li>
            <li>
              會員同意達賚得依實際執行情形，增加、修改或終止相關活動，並選擇最適方式告知會員。
            </li>
            <li>
              會員同意達賚得不定期發送電子報或商品訊息(EDM)至會員所登錄的電子信箱帳號。當會員收到訊息後表示拒絕接受行銷時，達賚將停止繼續發送行銷訊息。
            </li>
          </ol>
        </li>
        <li>
          約定條款修改
          <br />
          會員同意達賚得修改Bonny
          Go站會員約定條款，並同意達賚於修改後，得以言詞、書面、電話、簡訊、電子郵件、傳真、電子文件或其它足以使會員知悉或可得知悉知方式（包括但不限於以前述方式告知提供詳載會員約定條款內容之網站聯結），告知修改內容及指定網頁。
          如您登錄資料或您的行為與上述「會員資料」內容不符或有違反，您同意並瞭解達賚將依此約定條款揭示內容處理，且表示您將自行承擔所有不便及相關法律責任。
        </li>
      </ol>
      <h5>網站會員服務</h5>
      <ol style={{ listStyleType: 'upper-alpha' }}>
        <li>
          個人資料保護
          <ol>
            <li>
              基於法律之規定、或受司法機關與其他有權機關基於法定程序之要求。
            </li>
            <li>為增進公共利益。</li>
            <li>為免除會員之生命、身體、自由或財產上之危險。</li>
            <li>您的會員資料遭受他人冒用，且為防止會員權益之重大危害。</li>
            <li>為防止他人權益之重大危害。</li>
            <li>經過會員本人書面同意。</li>
          </ol>
        </li>
        <li>
          智慧財產權
          <br />
          達賚所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由達賚或其他權利人依法擁有其智慧財產權；請務必於取得達賚及其他權利人書面同意或授權後，才能使用前述資料。
        </li>
        <li>
          服務暫停與中斷
          <br />
          本系統或功能可能因『例行性』之維護、改置或變動發生服務暫停或中斷，達賚將於該暫停或中斷前以電子郵件、公告或其他適當之方式告知會員。
          達賚將以合理之方式及技術，維護會員服務之正常運作。但如有下列任一情況，達賚將逕行暫停或中斷會員服務之全部或一部，且對因此所造成任何不便或損害，均不負任何賠償或補償之責任：
          <ol>
            <li>使用者有任何違反政府法令或本使用條款情形；</li>
            <li>天災或其他不可抗力之因素所導致之服務停止或中斷；</li>
            <li>
              非波力所得控制之事由而致會員服務資訊顯示不正確、或遭偽造、竄改、刪除或擷取、或致系統中斷或不能正常運作時；
            </li>
            <li>
              對會員服務相關軟硬體設備進行搬遷、更換、升級、保養或維修時；
            </li>
            <li>其他不可歸責於達賚之事由所致之服務停止或中斷。</li>
          </ol>
        </li>
        <li>
          網站連結
          <br />
          為方便您自行蒐集或取得資訊，本網站將提供非廣告連結，但此連結並不代表達賚與此類連結網站管理者有任何合夥、僱傭或其他類似關係，其他業者經營的網站性均由各該業者自行負責，達賚對任何的外部連結，不擔保其合適性、有效性、或即時性，且達賚不對連結網站所提供產品、服務或資訊提供擔保或其他責任，請您留意該連結網站之交易及資訊安全性。
        </li>
        <li>
          會員服務管理
          <br />
          <ol>
            <li>
              如您的會員資料遭受他人冒用、有違反交易安全或本網站會員約定條款等情形時，達賚基於維護交易安全之考量，將終止您的帳號或會員服務之使用，必要時並將您的會員註冊資料會員服務移除或刪除，您瞭解並同意達賚將不對此種不當使用負擔賠償或補償責任
            </li>
            <li>
              達賚可能因網站管理或其他因素，變更或終止部份或全部會員服務或活動，此類變更或終止均將貼示在Bonny
              Go網站相關網頁或以其他方式通知，建議您留意此類訊息。
            </li>
            <li>
              您可藉由網站服務通知達賚變更會員服務內容或終止會員服務，請注意達賚均將在您接獲電子郵件並回覆確認次日起，對您的會員服務進行變更或終止；在此提醒您，此類變更或終止，可能會使您失去部份或全部的會員優惠或權益，請您謹慎使用。
            </li>
          </ol>
        </li>
      </ol>
      <h5>其他</h5>
      <ol style={{ listStyleType: 'upper-alpha' }}>
        <li>
          本約定條款解釋、補充及適用均以中華民國法令為準據法。會員約定條款中任何條款之全部或一部份無效時，不影響其他約定之效力。
        </li>
        <li>因本條款所發生之訴訟，以台灣台北地方法院為第一審管轄法院。</li>
      </ol>
    </div>
  );
};

export default SignUpTerms;
