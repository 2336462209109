import { ASYNC_START, ASYNC_FINISHED } from './types';

export const asyncStart = () => dispatch => {
  dispatch({
    type: ASYNC_START,
  });
};

export const asyncFinished = () => dispatch => {
  dispatch({
    type: ASYNC_FINISHED,
  });
};
