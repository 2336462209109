import {
  ADD_ITEM,
  INCRE_ITEM,
  DECRE_ITEM,
  REMOVE_ITEM,
  SET_SHIPPING,
  CLEAR_CART,
} from '../actions/types';
import { v4 as uuid } from 'uuid';
import { shallowEqual } from 'react-redux';
import renderSubtotal from '../../utils/renderSubtotal';

const initialState = {
  items:
    (JSON.parse(localStorage.getItem('cart')) &&
      JSON.parse(localStorage.getItem('cart')).items) ||
    [],
  shippingMethod:
    (JSON.parse(localStorage.getItem('cart')) &&
      JSON.parse(localStorage.getItem('cart')).shippingMethod) ||
    '',
  subtotal:
    (JSON.parse(localStorage.getItem('cart')) &&
      JSON.parse(localStorage.getItem('cart')).subtotal) ||
    0,
  total:
    (JSON.parse(localStorage.getItem('cart')) &&
      JSON.parse(localStorage.getItem('cart')).total) ||
    0,
};

const calcTotal = state => {
  let subtotal = 0;
  for (let i = 0; i < state.items.length; i++) {
    subtotal += renderSubtotal(parseInt(state.items[i].qqt));
  }
  let fee;
  switch (state.shippingMethod) {
    case 'slow':
      fee = 0;
      break;
    case 'mid':
      fee = 300;
      break;
    case 'fast':
      fee = 500;
      break;
    default:
      fee = 0;
  }
  const total = subtotal + fee;
  return {
    ...state,
    subtotal,
    total,
  };
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_ITEM:
      // merge same item
      let addedState;
      let existsIndex;

      const exists = state.items.some(({ id, qqt, ...rest }, index) => {
        const toCompare = { ...payload };
        delete toCompare['qqt'];
        existsIndex = index;
        return shallowEqual(rest, toCompare);
      });

      if (exists) {
        addedState = {
          ...state,
          items: state.items.map((item, index) => {
            if (index === existsIndex) {
              return {
                ...item,
                qqt: parseInt(item.qqt) + parseInt(payload.qqt),
              };
            } else {
              return item;
            }
          }),
        };
      } else {
        const payloadWithKey = { ...payload, id: uuid() }; // payload as single item
        const { items, ...rest } = state;
        // addedState = { ...state, items: [...state.items, payloadWithKey] }
        addedState = { items: [...state.items, payloadWithKey], ...rest };
      }
      addedState = calcTotal(addedState);
      localStorage.setItem('cart', JSON.stringify(addedState));

      return addedState;
    case REMOVE_ITEM: // payload as item's id
      let removedState = {
        ...state,
        items: state.items.filter(item => item.id !== payload),
      };
      removedState = calcTotal(removedState);
      localStorage.setItem('cart', JSON.stringify(removedState));
      return removedState;
    case INCRE_ITEM:
      let increState = {
        ...state,
        items: [
          ...state.items.map(item => {
            if (item.id === payload) {
              return { ...item, qqt: parseInt(item.qqt) + 1 };
            } else {
              return item;
            }
          }),
        ],
      };
      increState = calcTotal(increState);
      localStorage.setItem('cart', JSON.stringify(increState));
      return increState;
    case DECRE_ITEM:
      let decreState = {
        ...state,
        items: [
          ...state.items.map(item => {
            if (item.id === payload) {
              return { ...item, qqt: parseInt(item.qqt) - 1 };
            } else {
              return item;
            }
          }),
        ],
      };
      decreState = calcTotal(decreState);
      localStorage.setItem('cart', JSON.stringify(decreState));
      return decreState;
    case SET_SHIPPING:
      let setShippedState = {
        ...state,
        shippingMethod: payload,
      };
      setShippedState = calcTotal(setShippedState);
      localStorage.setItem('cart', JSON.stringify(setShippedState));
      return setShippedState;
    case CLEAR_CART:
      localStorage.removeItem('cart');
      return {
        ...state,
        items: [],
        subtotal: 0,
        total: 0,
        shippingMethod: '',
      };
    default:
      return state;
  }
};
