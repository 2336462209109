import React, { Fragment, useState } from 'react';
import { Menu, Container, Icon, Dropdown } from 'semantic-ui-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/actions/auth.actions';
import { useWindowSize } from '../../utils/useWindowSize';
import Drawer from './Drawer';

const NavBar = ({ history }) => {
  const items = useSelector(state => state.cart.items);
  const [sideBar, setSideBar] = useState(false);
  const [width] = useWindowSize();
  const dispatch = useDispatch();

  const itemsNumber = items.reduce(
    (prev, cur) => parseInt(prev) + parseInt(cur.qqt),
    0
  );
  const handleLogout = () => {
    dispatch(logout());
    history.push('/login');
  };

  const handleDrawer = () => {
    setSideBar(prev => !prev);
  };

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const isAdmin = user && user.role === 'admin';
  const PrivateLinks = (
    <Fragment>
      <Dropdown
        icon="user outline"
        // labeled
        // button
        floating
        text={!!user ? user.name : ''}
        className="item icon"
      >
        <Dropdown.Menu>
          <Dropdown.Header content={`歡迎，${!!user && user.name}`} />
          <Dropdown.Divider />
          <Dropdown.Item
            as={NavLink}
            exact
            to="/dashboard"
            icon="user circle"
            text="個人檔案"
          />
          <Dropdown.Item
            as={NavLink}
            exact
            to="/user_orders"
            icon="list"
            text="我的訂單"
          />
          {isAdmin && (
            <Fragment>
              <Dropdown.Divider />
              <Dropdown.Item
                as={NavLink}
                exact
                to="/admin"
                icon="user secret"
                text="管理員"
              />
            </Fragment>
          )}
          <Dropdown.Divider />
          <Dropdown.Item icon="power off" text="登出" onClick={handleLogout} />
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item as={NavLink} exact to="/cart">
        <Icon name="shopping cart" />
        {itemsNumber}
      </Menu.Item>
    </Fragment>
  );
  const PublicLinks = (
    <Fragment>
      <Menu.Item as={NavLink} exact to="/login" name="會員登入" />
      <Menu.Item as={NavLink} exact to="/cart">
        <Icon name="shopping cart" />
        {itemsNumber}
      </Menu.Item>
    </Fragment>
  );

  return (
    <Fragment>
      <Menu
        className="nav-menu"
        color="red"
        inverted
        size={width > 768 ? 'massive' : 'large'}
      >
        <Container className="nav-container">
          <Menu.Item header as={NavLink} exact to="/">
            <LazyLoadImage
              src="/assets/image/bonny_logo_white.svg"
              width={120}
            />{' '}
          </Menu.Item>
          {width > 768 ? (
            <Menu.Menu position="right">
              {isAuthenticated ? PrivateLinks : PublicLinks}
            </Menu.Menu>
          ) : (
            <Menu.Menu position="right">
              <Menu.Item as={NavLink} exact to="/cart">
                <Icon name="shopping cart" />
                {itemsNumber}
              </Menu.Item>
              <Menu.Item icon="bars" onClick={handleDrawer} />
            </Menu.Menu>
          )}
        </Container>
      </Menu>
      <Drawer setOpen={setSideBar} open={sideBar} history={history} />
    </Fragment>
  );
};

export default withRouter(NavBar);
