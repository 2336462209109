import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
// import Wrapper from './Wrapper'
import styled from 'styled-components';

const Wrapper = styled.div`
  .segment {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 200;
  }
`;

const LoadingComponent = () => {
  return (
    <Wrapper>
      <Segment size="massive">
        <Dimmer active>
          <Loader>載入中...</Loader>
        </Dimmer>
      </Segment>
    </Wrapper>
  );
};

export default LoadingComponent;
