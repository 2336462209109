import React, { Fragment } from 'react';
import { Sidebar, Segment, Item, Dimmer } from 'semantic-ui-react';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { BiUserCircle, BiLogIn } from 'react-icons/bi';
import { FiPower } from 'react-icons/fi';
import { FaUserSecret } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/actions/auth.actions';
import styled from 'styled-components';

const Wrapper = styled.div`
  .visible.dimmer {
    z-index: 5;
    opacity: 0.5 !important;
  }
`;

const Drawer = ({ open, history, setOpen }) => {
  const user = useSelector(state => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isAdmin = user && user.role === 'admin';
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Sidebar
        as={Segment}
        animation="push"
        direction="left"
        visible={open}
        width="thin"
        inverted
      >
        <Item.Group divided>
          {isAuthenticated ? (
            <Fragment>
              <Item>
                <Item.Content>
                  歡迎，<strong>{!!user && user.name}</strong>!
                </Item.Content>
              </Item>
              <Item
                onClick={() => {
                  history.push('/dashboard');
                  setOpen(false);
                }}
              >
                <Item.Header>
                  <BiUserCircle size={30} />
                </Item.Header>
                <Item.Content>個人檔案</Item.Content>
              </Item>
              <Item
                onClick={() => {
                  history.push('/user_orders');
                  setOpen(false);
                }}
              >
                <Item.Header>
                  <AiOutlineUnorderedList size={30} />
                </Item.Header>
                <Item.Content>我的訂單</Item.Content>
              </Item>
              {isAdmin && (
                <Item
                  onClick={() => {
                    history.push('/admin');
                    setOpen(false);
                  }}
                >
                  <Item.Header>
                    <FaUserSecret size={30} />
                  </Item.Header>
                  <Item.Content>管理員</Item.Content>
                </Item>
              )}
              <Item
                onClick={() => {
                  dispatch(logout());
                  setOpen(false);
                }}
              >
                <Item.Header>
                  <FiPower size={30} />
                </Item.Header>
                <Item.Content>登出</Item.Content>
              </Item>
            </Fragment>
          ) : (
            <Item
              onClick={() => {
                history.push('/login');
                setOpen(false);
              }}
            >
              <Item.Header>
                <BiLogIn size={30} />
              </Item.Header>
              <Item.Content>會員登入</Item.Content>
            </Item>
          )}
        </Item.Group>
      </Sidebar>
      <Dimmer active={open} onClick={() => setOpen(false)} />
    </Wrapper>
  );
};

export default Drawer;
